// import { useApiClient } from "./useApiClient";

interface CarsParamsInterface {
  brandId?: number;
  modelId?: number;
  generationId?: number;
}

export const useCarsApi = () => {
  // const apiClient = useApiClient();
  const { $axios } = useNuxtApp();
  const api = "/api/cars";

  const getCarBrands = () => {
    return $axios.get(`${api}/brands`);
  };

  const getCarModelsByBrand = ({ brandId }: CarsParamsInterface) => {
    return $axios.get(`${api}/brands/${brandId}/models`);
  };

  const getCarGenerationsByModel = ({
    brandId,
    modelId,
  }: CarsParamsInterface) => {
    return $axios.get(`${api}/brands/${brandId}/models/${modelId}/generations`);
  };

  const getCarEnginesByGeneration = ({
    brandId,
    modelId,
    generationId,
  }: CarsParamsInterface) => {
    return $axios.get(
      `${api}/brands/${brandId}/models/${modelId}/generations/${generationId}/engines/types`
    );
  };

  const getCarCapacityByGeneration = ({
    brandId,
    modelId,
    generationId,
  }: CarsParamsInterface) => {
    return $axios.get(
      `${api}/brands/${brandId}/models/${modelId}/generations/${generationId}/engines/capacities`
    );
  };

  const getCarTransmissionsByGeneration = ({
    brandId,
    modelId,
    generationId,
  }: CarsParamsInterface) => {
    return $axios.get(
      `${api}/brands/${brandId}/models/${modelId}/generations/${generationId}/transmissions/types`
    );
  };

  return {
    getCarBrands,
    getCarModelsByBrand,
    getCarGenerationsByModel,
    getCarEnginesByGeneration,
    getCarCapacityByGeneration,
    getCarTransmissionsByGeneration,
  };
};
