<template>
  <div class="request-form">
    <div>
      <v-select
        v-model="currentCar.car_brand_id"
        :disabled="loading"
        label="Марка"
        :items="carsList.brands"
        variant="outlined"
        item-title="name"
        item-value="id"
        @update:modelValue="getCarModelsByBrand"
        :error-messages="errors.car_brand_id"
        class="main-field mb-2"
      ></v-select>

      <v-select
        v-model="currentCar.car_model_id"
        :disabled="loading || currentCar.car_brand_id === null"
        label="Модель"
        :items="carsList.models"
        variant="outlined"
        item-title="name"
        item-value="id"
        @update:modelValue="getCarGenerationsByModel"
        :error-messages="errors.car_model_id"
        class="main-field mb-2"
      ></v-select>

      <v-select
        v-model="currentCar.car_generation_id"
        :disabled="loading || currentCar.car_model_id === null"
        label="Рік випуску"
        :items="getCarGenerations"
        variant="outlined"
        item-value="id"
        item-title="years"
        @update:modelValue="getCarInfoByGeneration"
        :error-messages="errors.car_generation_id"
        class="main-field mb-2"
      >
        <template v-slot:item="{ props, item }">
          <v-list-item
            v-bind="props"
            :title="`${item.raw.year_from} - ${item.raw.year_to || 'По зараз'}`"
          ></v-list-item>
        </template>
      </v-select>

      <v-select
        v-if="fullForm && currentCar.car_generation_id"
        v-model="currentCar.car_engine_capacity_id"
        :disabled="loading || currentCar.car_generation_id === null"
        label="Об'єм двигуна"
        :items="carsList.engineCapacities"
        variant="outlined"
        item-title="engine_capacity"
        item-value="id"
        :error-messages="errors.car_engine_capacity_id"
        @update:modelValue="errors.car_engine_capacity_id = null"
        class="main-field mb-2"
      ></v-select>

      <v-select
        v-if="fullForm && currentCar.car_generation_id"
        v-model="currentCar.car_engine_type_id"
        :disabled="loading || currentCar.car_generation_id === null"
        label="Тип двигуна"
        :items="carsList.engineTypes"
        variant="outlined"
        item-title="engine_type"
        item-value="id"
        :error-messages="errors.car_engine_type_id"
        @update:modelValue="errors.car_engine_type_id = null"
        class="main-field mb-2"
      ></v-select>

      <v-select
        v-if="fullForm && currentCar.car_generation_id"
        v-model="currentCar.car_transmission_id"
        :disabled="loading || currentCar.car_generation_id === null"
        label="Коробка передач"
        :items="carsList.transmissions"
        variant="outlined"
        item-title="transmission_type"
        item-value="id"
        :error-messages="errors.car_transmission_id"
        @update:modelValue="errors.car_transmission_id = null"
        class="main-field mb-2"
      ></v-select>
    </div>
  </div>
</template>

<script setup lang="ts">
import { type CurrentCarInterface } from "@/models/cars";

const carsStore = useCarsStore();

const props = defineProps({
  currentCarIds: {
    type: Object as PropType<CurrentCarInterface>,
    default: () => ({}),
  },
  modelValue: {
    type: Object as PropType<CurrentCarInterface>,
    default: () => ({}),
  },
  errorsValue: {
    type: Object as PropType<CurrentCarInterface>,
    default: () => ({}),
  },
  isEdit: {
    type: Boolean,
    default: false,
  },
  fullForm: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(["update:modelValue", "update:errorsValue"]);

const currentCar = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});

const errors = computed({
  get: () => props.errorsValue,
  set: (value) => emit("update:errorsValue", value),
});

const carsList = ref({
  brands: [],
  models: [],
  generations: [],
  engineTypes: [],
  engineCapacities: [],
  transmissions: [],
});

const loading = ref(true);

const getCarBrands = async () => {
  try {
    loading.value = true;
    const { data: response } = await carsStore.getCarBrands();
    carsList.value.brands = response.data;
  } catch (error) {
  } finally {
    loading.value = false;
  }
};

const getCarModelsByBrand = async () => {
  try {
    errors.value.car_brand_id = null;

    if (currentCar.value.car_model_id) {
      currentCar.value.car_model_id = null;
      currentCar.value.car_generation_id = null;
      currentCar.value.car_engine_type_id = null;
      currentCar.value.car_engine_capacity_id = null;
      currentCar.value.car_transmission_id = null;
    }

    loading.value = true;
    if (!currentCar.value.car_brand_id) return;

    const { data: response } = await carsStore.getCarModelsByBrand(
      currentCar.value.car_brand_id
    );
    carsList.value.models = response.data;
  } catch (error) {
  } finally {
    loading.value = false;
  }
};

const getCarGenerationsByModel = async () => {
  try {
    errors.value.car_model_id = null;

    if (!currentCar.value.car_brand_id || !currentCar.value.car_model_id)
      return;

    if (currentCar.value.car_generation_id) {
      currentCar.value.car_generation_id = null;
      currentCar.value.car_engine_type_id = null;
      currentCar.value.car_engine_capacity_id = null;
      currentCar.value.car_transmission_id = null;
    }

    loading.value = true;
    const { data: response } = await carsStore.getCarGenerationsByModel(
      currentCar.value.car_brand_id,
      currentCar.value.car_model_id
    );
    carsList.value.generations = response.data;
  } catch (error) {
  } finally {
    loading.value = false;
  }
};

const getCarInfoByGeneration = async () => {
  errors.value.car_generation_id = null;

  try {
    if (!currentCar.value.car_brand_id || !currentCar.value.car_model_id)
      return;

    if (
      currentCar.value.car_engine_capacity_id ||
      currentCar.value.car_engine_type_id ||
      currentCar.value.car_transmission_id
    ) {
      currentCar.value.car_engine_capacity_id = null;
      currentCar.value.car_engine_type_id = null;
      currentCar.value.car_transmission_id = null;
    }

    loading.value = true;
    await getCarEnginesByGeneration();
    await getCarCapacityByGeneration();
    await getCarTransmissionsByGeneration();
  } catch (error) {
  } finally {
    loading.value = false;
  }
};

const getCarEnginesByGeneration = async () => {
  try {
    if (
      !currentCar.value.car_brand_id ||
      !currentCar.value.car_model_id ||
      !currentCar.value.car_generation_id
    )
      return;
    loading.value = true;
    const { data: response } = await carsStore.getCarEnginesByGeneration(
      currentCar.value.car_brand_id,
      currentCar.value.car_model_id,
      currentCar.value.car_generation_id
    );
    carsList.value.engineTypes = response.data;
  } catch (error) {
  } finally {
    loading.value = false;
  }
};

const getCarCapacityByGeneration = async () => {
  try {
    if (
      !currentCar.value.car_brand_id ||
      !currentCar.value.car_model_id ||
      !currentCar.value.car_generation_id
    )
      return;
    loading.value = true;
    const { data: response } = await carsStore.getCarCapacityByGeneration(
      currentCar.value.car_brand_id,
      currentCar.value.car_model_id,
      currentCar.value.car_generation_id
    );
    carsList.value.engineCapacities = response.data;
  } catch (error) {
  } finally {
    loading.value = false;
  }
};

const getCarTransmissionsByGeneration = async () => {
  try {
    if (
      !currentCar.value.car_brand_id ||
      !currentCar.value.car_model_id ||
      !currentCar.value.car_generation_id
    )
      return;
    loading.value = true;
    const { data: response } = await carsStore.getCarTransmissionsByGeneration(
      currentCar.value.car_brand_id,
      currentCar.value.car_model_id,
      currentCar.value.car_generation_id
    );
    carsList.value.transmissions = response.data;
  } catch (error) {
  } finally {
    loading.value = false;
  }
};

const getCarGenerations = computed(() => {
  return carsList.value.generations.map((generation) => ({
    ...generation,
    years: `${generation.year_from} - ${generation.year_to || "По зараз"}`,
  }));
});

onMounted(async () => {
  await getCarBrands();

  if (props.isEdit) {
    currentCar.value.car_brand_id = props.currentCarIds.car_brand_id;

    await getCarModelsByBrand();

    currentCar.value.car_brand_id = props.currentCarIds.car_brand_id;
    currentCar.value.car_model_id = props.currentCarIds.car_model_id;

    await getCarGenerationsByModel();

    currentCar.value.car_brand_id = props.currentCarIds.car_brand_id;
    currentCar.value.car_model_id = props.currentCarIds.car_model_id;
    currentCar.value.car_generation_id = props.currentCarIds.car_generation_id;

    await getCarInfoByGeneration();

    currentCar.value = {
      car_brand_id: props.currentCarIds.car_brand_id,
      car_model_id: props.currentCarIds.car_model_id,
      car_generation_id: props.currentCarIds.car_generation_id,
      car_engine_type_id: props.currentCarIds.car_engine_type_id,
      car_engine_capacity_id: props.currentCarIds.car_engine_capacity_id,
      car_transmission_id: props.currentCarIds.car_transmission_id,
    };
  }
});
</script>

<style scoped lang="scss"></style>
